<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <b-col class="d-lg-none d-block py-1 bg-white">
        <b-link class="d-flex justify-content-center">
          <b-img
            src="./../../assets/images/avatars/image.png"
            rounded
            fluid
            alt="profile photo"
            class="bg-white logo_img"
          />
        </b-link>
      </b-col>
      <b-col lg="6" class="d-none d-lg-flex align-items-start mt-0">
        <div class="d-lg-flex justify-content-center sticky-top">
          <div class="mb-5">
            <!-- Brand logo  for large device -->
            <b-link class="brand-logo pb-5">
              <img src="./../../assets/images/logo/vep-logo.png" alt="" />
            </b-link>
          </div>
          <!-- Left Text for large device-->
          <div class="p-5">
            <b-img fluid :src="imgUrl" alt="Register V2" />
          </div>
        </div>
      </b-col>
      <!-- form-->
      <b-col
        lg="6"
        class="d-flex align-items-center auth-bg px-2 p-lg-5 pt-lg-0 pt-2 pb-5 overflow-auto form"
      >
        <b-col sm="8" md="6" lg="10" class="mx-auto full_time">
          <b-col sm="8" md="12" lg="12" class="mt-2">
            <h2 class="font-weight-bold mb-0">Full Time Opportunity</h2>
            <hr />
          </b-col>
          <!-- form -->
          <validation-observer ref="registerForm">
            <b-form class="auth-register-form mt-2">
              <b-row class="row">
                <b-col lg="6" md="12" sm="6">
                  <!-- current-salary -->
                  <b-form-group
                    label="Current Annual Salary in USD ($)"
                    label-for="current-salary"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Current Annual Salary"
                      rules="required"
                    >
                      <b-form-input
                        id="current-salary"
                        v-model="form.currentSalary"
                        type="number"
                        name="currentSalary"
                        :state="errors.length > 0 ? false : null"
                        placeholder="50000"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>

                  <!-- desired-salary -->
                </b-col>

                <b-col lg="6" md="12" sm="6">
                  <b-form-group
                    label="Are you open to relocate?"
                    label-for="relocate"
                    class=""
                  >
                    <div class="d-flex">
                      <b-form-radio
                        v-model="form.relocate"
                        name="relocate"
                        value="true"
                        class="mr-2"
                      >
                        Yes
                      </b-form-radio>
                      <b-form-radio
                        v-model="form.relocate"
                        name="relocate"
                        value="false"
                      >
                        No
                      </b-form-radio>
                    </div>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col lg="6" md="12" sm="12">
                  <b-form-group
                    label="Desired Annual Salary in USD ($)"
                    label-for="desired-salary"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Desired Annual Salary"
                      rules="required"
                    >
                      <b-form-input
                        id="desired-salary"
                        v-model="form.desiredSalary"
                        type="number"
                        name="desiredSalary"
                        :state="errors.length > 0 ? false : null"
                        placeholder="50000"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col lg="6" md="12" sm="12">
                  <b-form-group
                    label="Preferred Location"
                    label-for="preferred-location"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Preferred Location"
                      rules="required"
                    >
                      <b-form-group label-for="countries">
                        <v-select
                          ref="selectCountries"
                          v-model="form.countries"
                          multiple
                          label="name"
                          :options="countries"
                          :get-option-label="getSelected"
                        />
                      </b-form-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-col cols="12" class="d-flex justify-content-end">
                <b-button
                  variant="primary"
                  type="submit"
                  @click.prevent="validationForm"
                >
                  Next
                </b-button>
              </b-col>
            </b-form>
          </validation-observer>
        </b-col>
      </b-col>
      <!-- /Register-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import store from "@/store/index"
import VuexyLogo from "@core/layouts/components/Logo.vue"
import { togglePasswordVisibility } from "@core/mixins/ui/forms"
import { required } from "@validations"
import {
  BButton,
  BCardText,
  BCardTitle,
  BCol,
  BForm,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BFormRadio,
  BImg,
  BInputGroup,
  BInputGroupAppend,
  BLink,
  BRow,
} from "bootstrap-vue"
import { ValidationObserver, ValidationProvider } from "vee-validate"
import vSelect from "vue-select"

export default {
  components: {
    VuexyLogo,
    BRow,
    BImg,
    BCol,
    BLink,
    BButton,
    BForm,
    BFormRadio,
    BCardText,
    BCardTitle,
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    // validations
    ValidationProvider,
    ValidationObserver,
    vSelect,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      form: {
        currentSalary: null,
        desiredSalary: null,
        relocate: false,
        countries: [],
      },
      input: {
        country_id: 1,
      },
      countries: [],

      sideImg: require("@/assets/images/pages/register-v2.svg"),
      // validation
      required,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon"
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === "dark") {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require("@/assets/images/pages/register-v2-dark.svg")
        return this.sideImg
      }
      return this.sideImg
    },
  },
  created() {
    // setTimeout(() => {
    //   axios.all([this.fetchCountries()]);
    // }, 500);
    this.fetchCountries()
  },
  methods: {
    validationForm() {
      this.$refs.registerForm.validate().then((success) => {
        if (success) {
          // axios.post('/full-time-job', this.form).then(response => {
          //   if (response.data.status === 'success') {
          //     this.$router.push({ name: 'set-profile-picture' })
          //   }
          // })
          this.postForm()
        }
      })
    },

    postForm() {
      store.dispatch("auth/fullTimeForm", this.form).then((response) => {
        if (response.data.status === "success") {
          this.$router.push({ name: "set-profile-picture" })
        }
      })
    },

    fetchCountries() {
      // if already in store no need to dispatch
      if (store.state.auth.countries.length === 0) {
        store.dispatch("auth/getCountries").then((response) => {
          if (response.status === 200) {
            this.countries = store.state.auth.countries
          }
        })
      } else {
        this.countries = store.state.auth.countries
      }
    },

    getSelected(option) {
      return (option && option.name) || ""
    },
  },
}
/* eslint-disable global-require */
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
@import "@core/scss/vue/libs/vue-select.scss";
@media only screen and (max-width: 950px) {
  .form {
    margin-top: -80px;
  }
  .full_time {
    margin-top: -80px;
  }
  .logo_img {
    width: 120px !important;
    margin-left: -80px;
  }
}
</style>
